<template>
  <div class="content resize">
    <e-breadcrumbs />
    <h1 class="page_title">
      나의 할인쿠폰
    </h1><!-- 나의 할인쿠폰 -->
    <form id="frm2">
      <win-layer-pop class="my_surcharge_pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="surChargeParams"
        />
      </win-layer-pop>
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col :width="[serviceCtrCd === 'KR' ? '60px' : '80px']">
            <col width="150px">
            <col width="324px">
            <col width="50px">
            <col width="150px">
            <col width="180px">
            <col width="50px">
            <col width="120px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.FARE020T010.001') }}</th><!-- 출발 -->
              <td colspan="2">
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="params.porCtrCd"
                  :plc-cd="params.porPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <th>{{ $t('msg.FARE020T010.002') }}</th><!-- 도착 -->
              <td colspan="4">
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="params.dlyCtrCd"
                  :plc-cd="params.dlyPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.FARE020T010.003') }}</th><!-- 기간 -->
              <td>
                <select v-model="params.kind" class="wid150">
                  <option value="R">ETD 기준</option><!-- ETD 기준 -->
                </select>
              </td>
              <td colspan="3">
                <e-date-range-picker
                  id="date_picker"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
              <th style="text-align: right; padding-right: 15px;">{{ $t('msg.COUP100.014') }}</th><!-- TYPE -->
              <td colspan="2">
                <select v-model="params.type" class="wid150">
                  <option value="">선택</option>
                  <option value="GP">GP</option>
                  <option value="HC">HC</option>
                </select>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="searchMyOceanFreight(true)">{{ $t('msg.FARE020T010.013') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <div id="realgrid" class="mt5" style="width: 100%; height: 650px;" />
      </div>
    </form>
  </div>
</template>

<script>

import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import schedule from '@/api/rest/schedule/schedule'

import { rootComputed } from '@/store/helpers'

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import EBreadcrumbs from '@/components/common/EBreadcrumbs.vue'

let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'porCtrNm', dataType: 'text' }, // 출항지 국가
  { fieldName: 'porPlcCd', dataType: 'text' }, // 출항지 지역
  { fieldName: 'dlyCtrNm', dataType: 'text' }, // 도착지 국가
  { fieldName: 'dlyPlcCd', dataType: 'text' }, // 도착지 지역
  { fieldName: 'useStrDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 시작일
  { fieldName: 'useEndDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // 만료일
  { fieldName: 'cntrTypCd', dataType: 'text' }, // CNTR TYPE
  { fieldName: '20DcAMt', dataType: 'text' }, // 20 DC 금액
  { fieldName: '40DcAMt', dataType: 'text' }, // 40 DC 금액
  { fieldName: 'remark', dataType: 'text' } //비고
]

const columns = [
  { name: 'porCtrNm', fieldName: 'porCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'porPlcCd', fieldName: 'porPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 15 },
  { name: 'dlyCtrNm', fieldName: 'dlyCtrNm', header: { text: app.$t('msg.COUP100.005') }, editable: false, width: 15 },
  { name: 'dlyPlcCd', fieldName: 'dlyPlcCd', header: { text: app.$t('msg.COUP100.006') }, editable: false, width: 15 },
  { name: 'useStrDt', fieldName: 'useStrDt', header: { text: app.$t('msg.COUP100.007') }, editable: false, width: 20 },
  { name: 'useEndDt', fieldName: 'useEndDt', header: { text: app.$t('msg.COUP100.008') }, editable: false, width: 20 },
  { name: 'cntrTypCd', fieldName: 'cntrTypCd', header: { text: app.$t('msg.COUP100.009') }, editable: false, width: 15 },
  { name: '20DcAMt', fieldName: '20DcAMt', header: { text: app.$t('msg.COUP100.011') }, editable: false, width: 20 },
  { name: '40DcAMt', fieldName: '40DcAMt', header: { text: app.$t('msg.COUP100.012') }, editable: false, width: 20 },
  { name: 'remark', fieldName: 'remark', header: { text: app.$t('msg.COUP100.013') }, editable: false, width: 30 }
]

const layout = [
    {
      name: app.$t('msg.COUP100.003'),
      items: [
        { column: 'porCtrNm' },
        { column: 'porPlcCd' }
      ]
    },
    {
      name: app.$t('msg.COUP100.004'),
      items: [
        { column: 'dlyCtrNm' },
        { column: 'dlyPlcCd' }
      ]
    },
    'useStrDt',
    'useEndDt',
    'cntrTypCd',
    {
      name: app.$t('msg.COUP100.010'),
      items: [
        { column: '20DcAMt' },
        { column: '40DcAMt' }
      ]
    },
    'remark'
  ]

export default {
  name: 'KmtcCoupon',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'fre-surcharge-pop': () => import('@/pages/schedule/popup/FreSurchargePop'),
    'request-quote-pop': () => import('@/pages/schedule/popup/RequestQuotePop')
  },
  data () {
    return {
      gridHeight: 510,
      customComponent: null,
      params: {
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        kind: 'R',
        fromDt: '',
        toDt: '',
        type: ''
      },
      isSearch: false,
      format: 'YYYY.MM.DD',
      myOceanFreightList: [],
      // 운임 팝업을 띄우기 위해 필요한 데이터
      surChargeParams: {},
      dtChk: true,
      serviceCtrCd: '',
      relYn: true, // JamSin
      raNo: '',
      scNo: '',
      porCtrNm: '',
      porPlcNm: '',
      dlyCtrNm: '',
      dlyPlcNm: '',
      freExpDate: '',
      actShpNm: '',
      req20Ft: '', // 20FT 요청 금액
      req40Ft: '', // 40FT 요청 금액
      apv20FtList: [], // 20FT 승인 금액 리스트
      apv40FtList: [], // 40FT 승인 금액 리스트
      apv20Ft: '',
      apv40Ft: '',
      dlyCtrCd: '', // 도착지 국가 코드
      dlyCtrLssYn: '', // 도착지 국가 LSS 대상 여부
      lssYn: 'Y', // LSS 포함 여부
      negoAfterText: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // 비로그인 kmtc on 진입 금지
    if (!this.auth.userId) {
      this.$router.push('/main')
    } else { // 로그인 후 미설정 화주 kmtc on 진입금지
      // 가로배너 클릭, kmtc on 아이콘 클릭 구분하기 위해 로그 전달
      schedule.getKmtcSpotTarget('01').then((response) => {
        if (response.data[0].kmtcSpotNegoYn !== 'Y') {
          this.$router.push('/main')
        }
      })
    }

    this.init()
    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      this.raNo = vo.frtAppNo
      this.porPlcNm = vo.porPlcNm
      this.porCtrNm = vo.porCtrNm
      this.dlyPlcNm = vo.dlyPlcNm
      this.dlyCtrNm = vo.dlyCtrNm
      this.freExpDate = vo.expDate.replaceAll('-', '/')
      this.apv20Ft = vo.apv20Ft
      this.apv40Ft = vo.apv40Ft

      this.surChargeParams = Object.assign({}, this.surChargeParams, { porPlcCd: vo.porPlcCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { porCtrCd: vo.porCtrCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { dlyPlcCd: vo.dlyPlcCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { dlyCtrCd: vo.dlyCtrCd })
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    window.vmAppWpx = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setColumnLayout(layout)

    gridView.displayOptions.fitStyle = 'even'

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = 60
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    gridView.setFooter({
      visible: false
    })
    //JamSin 속도 문제로 막음
    //this.searchMyOceanFreight()

    //Grid 메소드
    this.gridEvents()

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo1 = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'M', startGap: 1, endCode: 'M', endGap: 0 })
    this.params.fromDt = arrMonthInfo1.stDt
    this.params.toDt = arrMonthInfo1.enDt

    this.searchMyOceanFreight(true) // 검색 실행

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'W', endGap: 1 })
    this.params.fromDt = arrMonthInfo.stDt
    this.params.toDt = arrMonthInfo.enDt
  },
  methods: {
    init () {
      this.gridHeight = 510

      this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }
    },
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchMyOceanFreight(true)
        }
      }
    },
    changePol (vo) {
      this.params.porCtrCd = vo.ctrCd
      this.params.porPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.params.dlyCtrCd = vo.ctrCd
      this.params.dlyPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    async searchMyOceanFreight (searchBtnClick) {
      // 조회
      if (this.fnValidationAll()) {
        // '검색'버튼을 누른 경우
        if (searchBtnClick !== undefined && searchBtnClick) {
          this.isSearch = true
        }

        const formData = JSON.parse(JSON.stringify(this.params))

        if (this.$ekmtcCommon.isNotEmpty(formData.fromDt)) {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (this.$ekmtcCommon.isNotEmpty(formData.toDt)) {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        if (!this.isSearch || (this.$ekmtcCommon.isEmpty(formData.fromDt) && this.$ekmtcCommon.isEmpty(formData.toDt))) {
          formData.kind = 'D'
        }

        this.myOceanFreightList = []

        await schedule.myOceanFreightPremium(formData).then((response) => {
            if (response.status === 200) {
              const respOceanFreightList = response.data.myOceanFreightList

              if (respOceanFreightList === undefined || respOceanFreightList.length === 0) {
                this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
              }

              if (respOceanFreightList.length > 0) {
                let imsiFrtAppNo = null
                let imsiFrtJson = {}
                let gp20 = ''
                let gp40 = ''

                // 연속 데이터 가공 loop
                respOceanFreightList.forEach((respOceanFreight, index) => {
                  if (imsiFrtAppNo === respOceanFreight.frtAppNo) {
                    // 이전 FrtAppNo와 현재 FrtAppNo가 같을 때는 cntrTypCd 값과 cgoTypNm 값만
                    // 배열에 저장한다. (나중에 중복 제거하고 ','로 join할 예정)
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                      imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                    }
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                      imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                    }
                    if (respOceanFreight.cntrSzCd === '20' && respOceanFreight.cntrTypCd === 'GP' && respOceanFreight.cgoTypCd !== '01') {
                      gp20 = respOceanFreight.rate
                    }
                    if (respOceanFreight.cntrSzCd === '40' && respOceanFreight.cntrTypCd === 'HC' && respOceanFreight.cgoTypCd !== '01') {
                      gp40 = respOceanFreight.rate
                    }
                  } else {
                    // 이전 FrtAppNo와 현재 FrtAppNo가 같지 않을 때는
                    // 실제 그리드에서 쓸 데이터 변수에 임시 변수 값을 넣어준다.
                    if (this.$ekmtcCommon.isNotEmpty(imsiFrtAppNo)) {
                      this.myOceanFreightList.push(imsiFrtJson)
                    }

                    imsiFrtAppNo = respOceanFreight.frtAppNo
                    imsiFrtJson = JSON.parse(JSON.stringify(respOceanFreight))

                    imsiFrtJson.cntrTypCd = []
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                      imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                    }
                    imsiFrtJson.cgoTypNm = []
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                      imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                    }
                    imsiFrtJson.oceFrt = []
                    if (respOceanFreight.cntrSzCd === '20' && respOceanFreight.cntrTypCd === 'GP' && respOceanFreight.cgoTypCd !== '01') {
                      gp20 = respOceanFreight.rate
                    }
                    if (respOceanFreight.cntrSzCd === '40' && respOceanFreight.cntrTypCd === 'HC' && respOceanFreight.cgoTypCd !== '01') {
                      gp40 = respOceanFreight.rate
                    }
                  }

                  // oceFrt 항목 추가
                  if (gp20 !== '' && gp40 !== '' && this.$ekmtcCommon.isEmpty(imsiFrtJson.oceFrt)) {
                    imsiFrtJson.oceFrt.push(gp20 + '/' + gp40)
                    this.apv20FtList.push(gp20)
                    this.apv40FtList.push(gp40)
                    gp20 = ''
                    gp40 = ''
                  }

                  if (index === (respOceanFreightList.length - 1)) {
                    this.myOceanFreightList.push(imsiFrtJson)
                  }
                })

                // 컨테이너 타입, 카고 타입 중복 데이터 삭제
                this.myOceanFreightList.forEach(myOceanFreight => {
                  myOceanFreight.cntrTypCd = myOceanFreight.cntrTypCd.length > 0 ? [...new Set(myOceanFreight.cntrTypCd)].sort().join(',') : ''
                  myOceanFreight.cgoTypNm = myOceanFreight.cgoTypNm.length > 0 ? [...new Set(myOceanFreight.cgoTypNm)].sort().join(',') : ''
                })
              }

              // //그리드 데이터 셋팅
              provider.setRows(this.myOceanFreightList)
            }
          })
      }
    },
    gridEvents () {
      const THIS = this
      gridView.onCellItemClicked = function (grid, index, clickData) {
        const current = gridView.getCurrent()
        const fieldName = current.fieldName

        // 선택 버튼 제어
        if (fieldName === 'frtAppNo' || fieldName === 'negoSts') {
          const selectedRowData = THIS.myOceanFreightList[current.dataRow]

          THIS.surChargeParams = {
            porCtrCd: selectedRowData.porCtrCd,
            porPlcCd: selectedRowData.porPlcCd,
            dlyCtrCd: selectedRowData.dlyCtrCd,
            dlyPlcCd: selectedRowData.dlyPlcCd,
            frtAppNo: selectedRowData.frtAppNo,
            frtResult: 'Y',
            polNm: selectedRowData.porPlcNm,
            podNm: selectedRowData.dlyPlcNm,
            fromDt: selectedRowData.strDt,
            toDt: selectedRowData.endDt,
            frtEstnYn: selectedRowData.frtEstnYn,
            frtExtsStsCd: selectedRowData.frtExtsStsCd,
            cstBidgMgrNo: selectedRowData.cstBidgMgrNo,
            cntrTypCd: (app.$ekmtcCommon.isEmpty(selectedRowData.cntrTypCd) ? '' : selectedRowData.cntrTypCd.split(',')[0]),
            hotDealYn: selectedRowData.hotDealYn
          }

          // 운임확인 팝업
          if (fieldName === 'frtAppNo') {
            THIS.openPopup('fre-surcharge-pop')
          }
        } else if (fieldName === 'endDt' && $(clickData.target).hasClass('button')) {
          const frtEstnYn = provider.getValue(current.dataRow, 'frtEstnYn')

          if (frtEstnYn !== 'I') {
            const frtExtsStsCd = provider.getValue(current.dataRow, 'frtExtsStsCd')
            const cstBidgMgrNo = provider.getValue(current.dataRow, 'cstBidgMgrNo')
            const endDt = provider.getValue(current.dataRow, 'endDt')

            const expDate = app.$ekmtcCommon.getStrToDate(endDt)
            const today = new Date()
            const twoWeekAfter = new Date().setDate(today.getDate() + 14)

            // 연장요청 가능한 상태일때만
            if (((today <= expDate && expDate <= twoWeekAfter) && frtExtsStsCd === '00' && THIS.$ekmtcCommon.isEmpty(cstBidgMgrNo)) ||
            (today > expDate && frtExtsStsCd === '00' && app.$ekmtcCommon.isEmpty(cstBidgMgrNo))
            ) {
              // 연장요청
              schedule.validityRequest({ frtAppNo: provider.getValue(current.dataRow, 'frtAppNo') })
                .then((response) => {
                  const alertOpt = {
                    alertType: 'simple',
                    customCloseBtnText: app.$t('msg.FARE020T010.027') // 확인
                  }

                  if (response.headers.respcode === 'C0000') {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.034'), // 연장요청이 완료되었습니다. 연장요청이 승인되면 '나의 운임' 메뉴에서 새로운 S/C No. 를 확인하실 수 있습니다.
                      onClose: () => { THIS.searchMyOceanFreight() }
                    })
                  } else {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.029') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의 부탁드립니다.
                    })
                  }
                })
            }
          }
        }
      }
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }

      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
    },
    closePopup (obj) {
      if (obj !== undefined && obj.negoYN === 'Y') {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
        this.customComponent = 'request-quote-pop'
        this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }
    },
    // '검색'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frm2')
      let isAllOk = true

      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }

      return isAllOk && this.dtChk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#polPol')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd))) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#podPod')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd))) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 시작일 validation check
    fnValidationFromDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt)) {
        msg = '시작일을 선택해 주세요.' // 시작일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 종료일 validation check
    fnValidationToDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        msg = '종료일을 선택해 주세요.' // 종료일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    changeDateRange (sdt, edt) {
      const frm = document.querySelector('#frm2')
      const selector = frm.querySelector('#date_picker')

      this.params.fromDt = sdt
      this.params.toDt = edt

      if (parseInt(this.params.fromDt, 10) > parseInt(this.params.toDt, 10)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
        this.dtChk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(selector)
        this.dtChk = true
      }
    }
  }
}
</script>
