<template>
  <!-- 운임 >> 나의 운임 -->
  <div class="content resize">
    <e-breadcrumbs />
    <h1 class="page_title">
      KMTC ON NOW로 지금 즉시 운임을 협의해보세요!
      <a class="button lg" href="#" @click.prevent="pdfDownload('KMTC_ON_NOW_FAQ_240821.pdf')"> [KMTC ON NOW 이용 가이드 다운로드 (CLICK)]</a><!-- CLP form Download //-->
    </h1><!-- 나의운임 -->
    <form id="frm2">
      <win-layer-pop class="my_surcharge_pop">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="surChargeParams"
        />
      </win-layer-pop>
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col :width="[serviceCtrCd === 'KR' ? '60px' : '80px']">
            <col width="150px">
            <col width="324px">
            <col width="50px">
            <col width="150px">
            <col width="150px">
            <col width="80px">
            <col width="120px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.FARE020T010.001') }}</th><!-- 출발 -->
              <td colspan="2">
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="params.porCtrCd"
                  :plc-cd="params.porPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <th>{{ $t('msg.FARE020T010.002') }}</th><!-- 도착 -->
              <td colspan="4">
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="params.dlyCtrCd"
                  :plc-cd="params.dlyPlcCd"
                  :is-char="true"
                  :is-char-alert="true"
                />
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.FARE020T010.003') }}</th><!-- 기간 -->
              <td>
                <select v-model="params.kind" class="wid150">
                  <!--                  <option value="E">{{ $t('msg.FARE020T010.004') }}</option>&lt;!&ndash; Expiry Date &ndash;&gt;-->
                  <option value="R">{{ $t('msg.FARE020T010.005') }}</option><!-- Request Date -->
                  <!--                  <option value="S">{{ $t('msg.FARE020T010.006') }}</option>&lt;!&ndash; Shipment Date &ndash;&gt;-->
                </select>
              </td>
              <td colspan="5">
                <e-date-range-picker
                  id="date_picker"
                  :sdate="params.fromDt"
                  :edate="params.toDt"
                  :is-debug="true"
                  @change="changeDateRange"
                />
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="searchMyOceanFreight(true)">{{ $t('msg.FARE020T010.013') }}</a><!-- 검색 -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <div class="flex_box">
          <span style="width: 44%; float: left; color: #075bb9; font-weight: 500; font-size: 17px; margin-left: 10px;">R/A (S/C) No : {{ raNo }} <span style="font-size: 15px; color: red;"> {{ negoAfterText }} </span></span>
          <span style="width: 55%; float: right; font-size: 15px;">하기 운임 LIST의 운임은 OCEAN FREIGHT와 선적지 LSS 포함 여부만 표시됩니다.<br />
            기타 LOCAL CHARGE, SURCHARGE를 확인하시려면 S/C No.를 클릭 부탁드립니다.
          </span>
        </div>
        <div class="content_box beforetab" style="display: inline-block; width: 44%; margin-right: 10px;">
          <table class="tbl_search">
            <colgroup>
              <col width="100px">
              <col width="50px">
              <col width="50px">
              <col width="50px">
              <col width="50px">
              <col width="100px">
              <col width="100px">
            </colgroup>
            <tbody>
              <tr><th></th></tr>
              <tr>
                <th>POR (국가)</th>
                <td colspan="4">
                  <input type="text" id="porPlcNm" v-model="porPlcNm" readonly />
                </td>
                <td colspan="4">
                  <input type="text" id="porCtrNm" v-model="porCtrNm" readonly />
                </td>
              </tr>
              <tr>
                <th>DLY (국가)</th>
                <td colspan="4">
                  <input type="text" id="dlyPlcNm" v-model="dlyPlcNm" readonly />
                </td>
                <td colspan="4">
                  <input type="text" id="dlyCtrNm" v-model="dlyCtrNm" readonly />
                </td>
              </tr>
              <tr>
                <th>운임유효기간</th>
                <td colspan="4">
                  <input type="text" id="freExpDate" v-model="freExpDate" readonly />
                </td>
              </tr>
              <br />
              <tr>
                <td colspan="10">
                  <div style="font-size: 16px;">
                    KMTC ON NOW를 이용해서 운임을 협의해보세요.<br />
                    하기 정보를 입력해주시면 즉시 협의된 운임을 확인하실 수 있습니다.<br />
                    <br />
                    (요청해주신 IDEAL RATE 수준에 따라 <br />
                    &nbsp;원하시는 협의 운임이 도출되지 않을 수 있는 점 양해 부탁드립니다.) <br />
                  </div>
                </td>
              </tr>
              <br />
              <tr>
                <th>실화주</th>
                <td colspan="5">
                  <input type="text" id="actShpNm" v-model="actShpNm" />
                </td>
              </tr>
              <tr>
                <th>IDEAL RATE</th>
                <th colspan="2">20FT (USD)</th>
                <td colspan="3">
                  <input type="text" id="req20Ft" v-model="req20Ft" />
                </td>
              </tr>
              <tr>
                <th></th>
                <th colspan="2">40FT (USD)</th>
                <td colspan="3">
                  <input type="text" id="req40Ft" v-model="req40Ft" />
                </td>
              </tr>
              <tr><th></th></tr>
              <tr>
                <th></th>
                <td colspan="6">
                  <input type="checkbox" id="lssYn" name="lssYn" v-model="lssYn" true-value="Y" false-value="N" />
                  <label for="lssYn" class="mr10"><span></span>선적지 LSS 포함 기준으로 IDEAL RATE를 제출하겠습니다.(선택)</label>
                  <br />
                </td>
              </tr>
              <tr><th></th><td></td></tr>
              <tr><th></th><td></td></tr>
              <tr>
                <td colspan="7" class="text_right">
                  <a class="button blue sh" href="#" @click.prevent="kmtcOnNegoRequest()">KMTC ON NOW 운임 협의하기</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="realgrid" :style="`display: inline-block; width: 55%; height: ${gridHeight}px;`" />
      </div>
      <br />
    </form>
  </div>
</template>

<script>

import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import schedule from '@/api/rest/schedule/schedule'

import { rootComputed } from '@/store/helpers'

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import EBreadcrumbs from '@/components/common/EBreadcrumbs.vue'
import intergis from '@/api/rest/common/intergis'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'negoSts', dataType: 'text' }, // 네고상태.
  { fieldName: 'frtAppNo', dataType: 'text' }, // R/A No.
  { fieldName: 'porPlcCd', dataType: 'text' }, // POR
  { fieldName: 'dlyPlcCd', dataType: 'text' }, // DLY
  { fieldName: 'oceFrt', dataType: 'text' }, // Ocean Freight
  { fieldName: 'strDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }, // Effective Date
  { fieldName: 'frtPncNm', dataType: 'text' }, // Payment Term
  { fieldName: 'cntrTypCd', dataType: 'text' }, // Container Type
  { fieldName: 'cgoTypNm', dataType: 'text' }, // Cargo Type
  { fieldName: 'frtExtsStsCd', dataType: 'text' },
  { fieldName: 'frtEstnYn', dataType: 'text' },
  { fieldName: 'cstBidgMgrNo', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'porCtrCd', dataType: 'text' },
  { fieldName: 'porCtrNm', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrCd', dataType: 'text' },
  { fieldName: 'dlyCtrNm', dataType: 'text' },
  { fieldName: 'apvUno', dataType: 'text' },
  { fieldName: 'dcYn', dataType: 'text' },
  { fieldName: 'lssWaiveYn', dataType: 'text' },
  { fieldName: 'endDt', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' }
]

export const columns = [
  {
    name: 'negoSts',
    fieldName: 'negoSts',
    type: 'text',
    width: '8',
    header: { text: 'Nego' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const raNo = grid.getValue(idx, 'frtAppNo')
        const scNo = grid.getValue(idx, 'frtAppNo')
        const porCtrNm = grid.getValue(idx, 'porCtrNm')
        const porPlcNm = grid.getValue(idx, 'porPlcNm')
        const dlyCtrNm = grid.getValue(idx, 'dlyCtrNm')
        const dlyPlcNm = grid.getValue(idx, 'dlyPlcNm')
        const strDt = grid.getValue(idx, 'strDt')
        const endDt = grid.getValue(idx, 'endDt')
        const apvUno = grid.getValue(idx, 'apvUno')
        const dcYn = grid.getValue(idx, 'dcYn')
        const dlyCtrCd = grid.getValue(idx, 'dlyCtrCd')

        const freExpDate = strDt.getFullYear() + '/' + ('0' + (strDt.getMonth() + 1)).slice(-2) + '/' + ('0' + (strDt.getDate())).slice(-2) +
                           ' ~ ' + endDt.getFullYear() + '/' + ('0' + (endDt.getMonth() + 1)).slice(-2) + '/' + ('0' + (endDt.getDate())).slice(-2)

        let negoChk = false

        if (dcYn !== 'Y' && apvUno === 'SYSTEM') {
          negoChk = true
        }

        if (negoChk) {
          return `<span class="yes"><a class="button sm" onclick="vmAppWpx.negoRequest(event, '${raNo}', '${scNo}', '${porCtrNm}', '${porPlcNm}', '${dlyCtrNm}', '${dlyPlcNm}', '${freExpDate}', '${dlyCtrCd}', '${idx}');">협의요청</a></span>`
        } else {
          return '<span class="yes"><a class="button sm" style="background-color: #fddd4d; color: black; font-size:12px;" onclick="">협의완료</a></span>'
        }
      }
    }
  },
  {
    name: 'frtAppNo',
    fieldName: 'frtAppNo',
    type: 'text',
    width: '7',
    header: { text: 'S/C No.' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<span><a style="text-decoration:underline;cursor:pointer;">${cell.value}</a></span>`
      }
    }
  },
  {
    name: 'porPlcCd',
    fieldName: 'porPlcCd',
    type: 'text',
    width: '8',
    header: { text: 'POR' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<p>${provider.getValue(cell.index.dataRow, 'porPlcNm')}</p><p>${provider.getValue(cell.index.dataRow, 'porCtrCd')}</p>`
      }
    }
  },
  {
    name: 'dlyPlcCd',
    fieldName: 'dlyPlcCd',
    type: 'text',
    width: '8',
    header: { text: 'DLY' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `<p>${provider.getValue(cell.index.dataRow, 'dlyPlcNm')}</p><p>${provider.getValue(cell.index.dataRow, 'dlyCtrCd')}</p>`
      }
    }
  },
  {
    name: 'oceFrt',
    fieldName: 'oceFrt',
    type: 'text',
    width: '8',
    header: { text: '운임(20GP/40HC)' }
  },
  {
    name: 'lssWaiveYn',
    fieldName: 'lssWaiveYn',
    type: 'text',
    width: '6',
    header: { text: '선적지 LSS' },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const lssYn = grid.getValue(idx, 'lssWaiveYn')

        if (lssYn === 'Y') {
          return '<span style="color:red;">포함</span>'
        } else if (lssYn === 'N') {
          return '<span style="color:red;">별도</span>'
        } else {
          return ''
        }
      }
    }
  },
  {
    name: 'strDt',
    fieldName: 'strDt',
    type: 'text',
    width: '7',
    header: { text: 'Effective Date' },
    datetimeFormat: 'yyyy/MM/dd'
  },
  {
    name: 'frtPncNm',
    fieldName: 'frtPncNm',
    type: 'text',
    width: '6',
    header: { text: 'Payment Term' }
  },
  {
    name: 'cntrTypCd',
    fieldName: 'cntrTypCd',
    type: 'text',
    width: '6',
    header: { text: 'Container Type' }
  },
  {
    name: 'cgoTypNm',
    fieldName: 'cgoTypNm',
    visible: false,
    type: 'text',
    width: '4',
    header: { text: 'Cargo Type' }
  },
  {
    name: 'endDt',
    fieldName: 'endDt',
    visible: false,
    type: 'text',
    width: '8',
    header: { text: 'endDt' },
    datetimeFormat: 'yyyy/MM/dd'
  }
]

export default {
  name: 'KmtcOnPremium',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    'e-date-range-picker': () => import('@/components/common/EDateRangePicker'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'fre-surcharge-pop': () => import('@/pages/schedule/popup/FreSurchargePop'),
    'request-quote-pop': () => import('@/pages/schedule/popup/RequestQuotePop')
  },
  // props: {
  //   parentInfo: {
  //     type: Object,
  //     default: function () {
  //       return {
  //         frtAppNo: '',
  //         rmYn: ''
  //       }
  //     }
  //   }
  // },
  data () {
    return {
      gridHeight: 510,
      customComponent: null,
      params: {
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        kind: 'R',
        fromDt: '',
        toDt: '',
        frtAppNo: ''
      },
      isSearch: false,
      format: 'YYYY.MM.DD',
      myOceanFreightList: [],
      // 운임 팝업을 띄우기 위해 필요한 데이터
      surChargeParams: {},
      dtChk: true,
      serviceCtrCd: '',
      relYn: true, // JamSin
      raNo: '',
      scNo: '',
      porCtrNm: '',
      porPlcNm: '',
      dlyCtrNm: '',
      dlyPlcNm: '',
      freExpDate: '',
      actShpNm: '',
      req20Ft: '', // 20FT 요청 금액
      req40Ft: '', // 40FT 요청 금액
      apv20FtList: [], // 20FT 승인 금액 리스트
      apv40FtList: [], // 40FT 승인 금액 리스트
      apv20Ft: '',
      apv40Ft: '',
      dlyCtrCd: '', // 도착지 국가 코드
      dlyCtrLssYn: '', // 도착지 국가 LSS 대상 여부
      lssYn: 'Y', // LSS 포함 여부
      negoAfterText: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // 비로그인 kmtc on 진입 금지
    if (!this.auth.userId) {
      this.$router.push('/main')
    } else { // 로그인 후 미설정 화주 kmtc on 진입금지
      // 가로배너 클릭, kmtc on 아이콘 클릭 구분하기 위해 로그 전달
      schedule.getKmtcSpotTarget('01').then((response) => {
        if (response.data[0].kmtcSpotNegoYn !== 'Y') {
          this.$router.push('/main')
        }
      })
    }

    this.init()
    if (Object.keys(this.$route.query).length > 0) {
      const vo = this.$route.query

      this.raNo = vo.frtAppNo
      this.porPlcNm = vo.porPlcNm
      this.porCtrNm = vo.porCtrNm
      this.dlyPlcNm = vo.dlyPlcNm
      this.dlyCtrNm = vo.dlyCtrNm
      this.freExpDate = vo.expDate.replaceAll('-', '/')
      this.apv20Ft = vo.apv20Ft
      this.apv40Ft = vo.apv40Ft

      this.surChargeParams = Object.assign({}, this.surChargeParams, { porPlcCd: vo.porPlcCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { porCtrCd: vo.porCtrCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { dlyPlcCd: vo.dlyPlcCd })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { dlyCtrCd: vo.dlyCtrCd })
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    window.vmAppWpx = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.fitStyle = 'evenFill'

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = 60
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    gridView.setFooter({
      visible: false
    })
    //JamSin 속도 문제로 막음
    //this.searchMyOceanFreight()

    //Grid 메소드
    this.gridEvents()

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo1 = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'M', startGap: 1, endCode: 'M', endGap: 0 })
    this.params.fromDt = arrMonthInfo1.stDt
    this.params.toDt = arrMonthInfo1.enDt

    this.searchMyOceanFreight(true) // 검색 실행

    // JamSin 날짜 초기값 셋팅 -1w ~ +1w
    const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'W', endGap: 1 })
    this.params.fromDt = arrMonthInfo.stDt
    this.params.toDt = arrMonthInfo.enDt
  },
  methods: {
    init () {
      this.gridHeight = 510
      // this.gridHeight = $('body').height() - 477
      // if (this.gridHeight < 300) {
      //   this.gridHeight = 300
      // }

      this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')

      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }
    },
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter') {
          this.searchMyOceanFreight(true)
        }
      }
    },
    changePol (vo) {
      this.params.porCtrCd = vo.ctrCd
      this.params.porPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.params.dlyCtrCd = vo.ctrCd
      this.params.dlyPlcCd = vo.plcCd
      const frm = document.querySelector('#frm2')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    async searchMyOceanFreight (searchBtnClick) {
      // 조회
      if (this.fnValidationAll()) {
        // '검색'버튼을 누른 경우
        if (searchBtnClick !== undefined && searchBtnClick) {
          this.isSearch = true
        }

        const formData = JSON.parse(JSON.stringify(this.params))

        if (this.$ekmtcCommon.isNotEmpty(formData.fromDt)) {
          formData.fromDt = formData.fromDt.replaceAll('.', '')
        }
        if (this.$ekmtcCommon.isNotEmpty(formData.toDt)) {
          formData.toDt = formData.toDt.replaceAll('.', '')
        }

        if (!this.isSearch || (this.$ekmtcCommon.isEmpty(formData.fromDt) && this.$ekmtcCommon.isEmpty(formData.toDt))) {
          formData.kind = 'D'
        }

        this.myOceanFreightList = []

        await schedule.myOceanFreightPremium(formData).then((response) => {
            if (response.status === 200) {
              const respOceanFreightList = response.data.myOceanFreightList

              if (respOceanFreightList === undefined || respOceanFreightList.length === 0) {
                this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
              }

              if (respOceanFreightList.length > 0) {
                let imsiFrtAppNo = null
                let imsiFrtJson = {}
                let gp20 = ''
                let gp40 = ''

                // 연속 데이터 가공 loop
                respOceanFreightList.forEach((respOceanFreight, index) => {
                  if (imsiFrtAppNo === respOceanFreight.frtAppNo) {
                    // 이전 FrtAppNo와 현재 FrtAppNo가 같을 때는 cntrTypCd 값과 cgoTypNm 값만
                    // 배열에 저장한다. (나중에 중복 제거하고 ','로 join할 예정)
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                      imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                    }
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                      imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                    }
                    if (respOceanFreight.cntrSzCd === '20' && respOceanFreight.cntrTypCd === 'GP' && respOceanFreight.cgoTypCd !== '01') {
                      gp20 = respOceanFreight.rate
                    }
                    if (respOceanFreight.cntrSzCd === '40' && respOceanFreight.cntrTypCd === 'HC' && respOceanFreight.cgoTypCd !== '01') {
                      gp40 = respOceanFreight.rate
                    }
                  } else {
                    // 이전 FrtAppNo와 현재 FrtAppNo가 같지 않을 때는
                    // 실제 그리드에서 쓸 데이터 변수에 임시 변수 값을 넣어준다.
                    if (this.$ekmtcCommon.isNotEmpty(imsiFrtAppNo)) {
                      this.myOceanFreightList.push(imsiFrtJson)
                    }

                    imsiFrtAppNo = respOceanFreight.frtAppNo
                    imsiFrtJson = JSON.parse(JSON.stringify(respOceanFreight))

                    imsiFrtJson.cntrTypCd = []
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cntrTypCd)) {
                      imsiFrtJson.cntrTypCd.push(respOceanFreight.cntrTypCd)
                    }
                    imsiFrtJson.cgoTypNm = []
                    if (this.$ekmtcCommon.isNotEmpty(respOceanFreight.cgoTypNm)) {
                      imsiFrtJson.cgoTypNm.push(respOceanFreight.cgoTypNm)
                    }
                    imsiFrtJson.oceFrt = []
                    if (respOceanFreight.cntrSzCd === '20' && respOceanFreight.cntrTypCd === 'GP' && respOceanFreight.cgoTypCd !== '01') {
                      gp20 = respOceanFreight.rate
                    }
                    if (respOceanFreight.cntrSzCd === '40' && respOceanFreight.cntrTypCd === 'HC' && respOceanFreight.cgoTypCd !== '01') {
                      gp40 = respOceanFreight.rate
                    }
                  }

                  // oceFrt 항목 추가
                  if (gp20 !== '' && gp40 !== '' && this.$ekmtcCommon.isEmpty(imsiFrtJson.oceFrt)) {
                    imsiFrtJson.oceFrt.push(gp20 + '/' + gp40)
                    this.apv20FtList.push(gp20)
                    this.apv40FtList.push(gp40)
                    gp20 = ''
                    gp40 = ''
                  }

                  if (index === (respOceanFreightList.length - 1)) {
                    this.myOceanFreightList.push(imsiFrtJson)
                  }
                })

                // 컨테이너 타입, 카고 타입 중복 데이터 삭제
                this.myOceanFreightList.forEach(myOceanFreight => {
                  myOceanFreight.cntrTypCd = myOceanFreight.cntrTypCd.length > 0 ? [...new Set(myOceanFreight.cntrTypCd)].sort().join(',') : ''
                  myOceanFreight.cgoTypNm = myOceanFreight.cgoTypNm.length > 0 ? [...new Set(myOceanFreight.cgoTypNm)].sort().join(',') : ''
                })
              }

              // //그리드 데이터 셋팅
              provider.setRows(this.myOceanFreightList)
            }
          })
      }
    },
    // KMTC ON NEGO 제출
    async kmtcOnNegoRequest () {
      if (this.raNo.length < 1) {
        this.$ekmtcCommon.asyncAlertMessage({ message: '네고 요청할 운임을 선택하세요.' })
        return
      }

      if (this.actShpNm.length < 1) {
        this.$ekmtcCommon.asyncAlertMessage({ message: '실화주를 입력하세요.' })
        return
      }

      if (this.req20Ft < 1 || this.req40Ft < 1) {
        this.$ekmtcCommon.asyncAlertMessage({ message: '운임을 입력하세요.' })
        return
      }

      if (this.negoAfterText !== '') {
        this.$ekmtcCommon.asyncAlertMessage({ message: '네고 완료된 운임입니다.' })
        return
      }

      // 중국, 홍콩, 대만은 LSS 미포함
      if (this.dlyCtrCd === 'CN' || this.dlyCtrCd === 'HK' || this.dlyCtrCd === 'TW') {
        this.dlyCtrLssYn = 'N'
      } else {
        this.dlyCtrLssYn = 'Y'
      }

      this.surChargeParams = Object.assign({}, this.surChargeParams, { frtAppNo: this.raNo })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { actShpNm: this.actShpNm })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { req20Ft: this.req20Ft })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { req40Ft: this.req40Ft })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { apv20Ft: this.apv20Ft })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { apv40Ft: this.apv40Ft })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { lssYn: this.lssYn })
      this.surChargeParams = Object.assign({}, this.surChargeParams, { dlyCtrLssYn: this.dlyCtrLssYn })

      let errChk = false
      let msg = null

      await schedule.kmtcOnNegoRequest(this.surChargeParams).then((response) => {
        if (response.data !== '') {
          this.params.frtAppNo = response.data.frtAppNo

          if (response.data.negoYN === 'R') {
            msg = '요청하신 운임으로 조정이 완료되었습니다.'
          } else if (response.data.negoYN === 'K') {
            msg = '요청하신 운임으로 조정이 불가하여, 당사 기준으로 조정이 완료되었습니다.'
          }

          // 현재 RA운임이 최저운임보다 적은 경우 최저운임 미적용 안내 메세지
          if (response.data.negoYN === 'X') {
            msg = '현재 S/C번호 운임이 가장 저렴합니다.'
            this.negoAfterText = '현재 S/C번호 운임이 가장 저렴합니다.'
          } else {
            this.negoAfterText = '네고완료! 리스트의 SC번호 클릭하여 확인하세요.'
          }
        } else {
          errChk = true
        }
      })

      // 에러 체크하여 에러시 종료, 정상 처리시 네고된 운임 조회하여 리스트 노출함
      if (errChk) {
        this.$ekmtcCommon.asyncAlertMessage({ message: '해당 구간은 KMTC ON NOW로 운임 조정이 불가한 구간입니다.<br>담당 영업사원에게 별도 문의를 부탁드립니다. 감사합니다.' })
      } else {
        this.params.kind = 'N' // 일반 검색과 구분하기 위해 네고 완료 구분자를 세팅하고, 아래 검색시 체크함
        this.searchMyOceanFreight(true) // 검색 실행
        this.$ekmtcCommon.asyncAlertMessage({ message: msg })
        this.params.kind = 'R' // 일반 검색 구분자(요청일자)
      }
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('negoSts', 'header')
      const header2 = gridView.getColumnProperty('frtAppNo', 'header')
      const header3 = gridView.getColumnProperty('porPlcCd', 'header')
      const header4 = gridView.getColumnProperty('dlyPlcCd', 'header')
      const header5 = gridView.getColumnProperty('oceFrt', 'header')
      const header6 = gridView.getColumnProperty('strDt', 'header')
      const header7 = gridView.getColumnProperty('frtPncNm', 'header')
      const header8 = gridView.getColumnProperty('cntrTypCd', 'header')
      const header9 = gridView.getColumnProperty('cgoTypNm', 'header')

      header1.text = this.$t('msg.FARE020T010.036') // Nego.
      header2.text = this.$t('msg.FARE020T010.031') // S/C No.
      header3.text = this.$t('msg.FARE020T010.038') // POR
      header4.text = this.$t('msg.FARE020T010.039') // DLY
      header5.text = this.$t('msg.FARE020T010.037') // Freight(20GP/40HC)
      header6.text = this.$t('msg.FARE020T010.020') // Effective Date
      header7.text = this.$t('msg.FARE020T010.022') // Payment Term
      header8.text = this.$t('msg.FARE020T010.024') // Container Type
      header9.text = this.$t('msg.FARE020T010.025') // Cargo Type

      gridView.setColumnProperty('negoSts', 'header', header1)
      gridView.setColumnProperty('frtAppNo', 'header', header2)
      gridView.setColumnProperty('porPlcCd', 'header', header3)
      gridView.setColumnProperty('dlyPlcCd', 'header', header4)
      gridView.setColumnProperty('oceFrt', 'header', header5)
      gridView.setColumnProperty('strDt', 'header', header6)
      gridView.setColumnProperty('frtPncNm', 'header', header7)
      gridView.setColumnProperty('cntrTypCd', 'header', header8)
      gridView.setColumnProperty('cgoTypNm', 'header', header9)
    },
    gridEvents () {
      const THIS = this
      gridView.onCellItemClicked = function (grid, index, clickData) {
        const current = gridView.getCurrent()
        const fieldName = current.fieldName

        // 선택 버튼 제어
        if (fieldName === 'frtAppNo' || fieldName === 'negoSts') {
          const selectedRowData = THIS.myOceanFreightList[current.dataRow]

          THIS.surChargeParams = {
            porCtrCd: selectedRowData.porCtrCd,
            porPlcCd: selectedRowData.porPlcCd,
            dlyCtrCd: selectedRowData.dlyCtrCd,
            dlyPlcCd: selectedRowData.dlyPlcCd,
            frtAppNo: selectedRowData.frtAppNo,
            frtResult: 'Y',
            polNm: selectedRowData.porPlcNm,
            podNm: selectedRowData.dlyPlcNm,
            fromDt: selectedRowData.strDt,
            toDt: selectedRowData.endDt,
            frtEstnYn: selectedRowData.frtEstnYn,
            frtExtsStsCd: selectedRowData.frtExtsStsCd,
            cstBidgMgrNo: selectedRowData.cstBidgMgrNo,
            cntrTypCd: (app.$ekmtcCommon.isEmpty(selectedRowData.cntrTypCd) ? '' : selectedRowData.cntrTypCd.split(',')[0]),
            hotDealYn: selectedRowData.hotDealYn
          }

          // 운임확인 팝업
          if (fieldName === 'frtAppNo') {
            THIS.openPopup('fre-surcharge-pop')
          }
        } else if (fieldName === 'endDt' && $(clickData.target).hasClass('button')) {
          const frtEstnYn = provider.getValue(current.dataRow, 'frtEstnYn')

          if (frtEstnYn !== 'I') {
            const frtExtsStsCd = provider.getValue(current.dataRow, 'frtExtsStsCd')
            const cstBidgMgrNo = provider.getValue(current.dataRow, 'cstBidgMgrNo')
            const endDt = provider.getValue(current.dataRow, 'endDt')

            const expDate = app.$ekmtcCommon.getStrToDate(endDt)
            const today = new Date()
            const twoWeekAfter = new Date().setDate(today.getDate() + 14)

            // 연장요청 가능한 상태일때만
            if (((today <= expDate && expDate <= twoWeekAfter) && frtExtsStsCd === '00' && THIS.$ekmtcCommon.isEmpty(cstBidgMgrNo)) ||
            (today > expDate && frtExtsStsCd === '00' && app.$ekmtcCommon.isEmpty(cstBidgMgrNo))
            ) {
              // 연장요청
              schedule.validityRequest({ frtAppNo: provider.getValue(current.dataRow, 'frtAppNo') })
                .then((response) => {
                  const alertOpt = {
                    alertType: 'simple',
                    customCloseBtnText: app.$t('msg.FARE020T010.027') // 확인
                  }

                  if (response.headers.respcode === 'C0000') {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.034'), // 연장요청이 완료되었습니다. 연장요청이 승인되면 '나의 운임' 메뉴에서 새로운 S/C No. 를 확인하실 수 있습니다.
                      onClose: () => { THIS.searchMyOceanFreight() }
                    })
                  } else {
                    app.$ekmtcCommon.alert({
                      ...alertOpt,
                      message: app.$t('msg.FARE020T010.029') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의 부탁드립니다.
                    })
                  }
                })
            }
          }
        }
      }
    },
    openPopup (compNm) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }

      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
    },
    closePopup (obj) {
      if (obj !== undefined && obj.negoYN === 'Y') {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
        this.customComponent = 'request-quote-pop'
        this.$ekmtcCommon.layerOpen('.my_surcharge_pop')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.my_surcharge_pop')
      }
    },
    // '검색'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frm2')
      let isAllOk = true

      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }

      return isAllOk && this.dtChk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#polPol')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd))) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#podPod')
      const inputElem = elem.querySelector('input')
      const inputValue = inputElem.value
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isNotEmpty(inputValue) && (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd))) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 시작일 validation check
    fnValidationFromDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.fromDt)) {
        msg = '시작일을 선택해 주세요.' // 시작일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    // 종료일 validation check
    fnValidationToDt (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frm2')
      }

      const elem = frm.querySelector('#date_picker')
      let isOk = true
      let msg = ''

      if (this.$ekmtcCommon.isEmpty(this.params.toDt)) {
        msg = '종료일을 선택해 주세요.' // 종료일을 선택해 주세요.
        isOk = false
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      return isOk
    },
    changeDateRange (sdt, edt) {
      const frm = document.querySelector('#frm2')
      const selector = frm.querySelector('#date_picker')

      this.params.fromDt = sdt
      this.params.toDt = edt

      if (parseInt(this.params.fromDt, 10) > parseInt(this.params.toDt, 10)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
        this.dtChk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(selector)
        this.dtChk = true
      }
    },
    exlDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('menu.MENU05.000') + '_' + this.$t('menu.MENU05.020'), // 업무 대행정보 리스트
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        //allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU05.000') + '_' + this.$t('menu.MENU05.020')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    },
    async pdfDownload (fileName) {
      const result = await intergis.excelDownload(fileName)
      console.log('excelDownload')
      console.log(result)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    async negoRequest (e, raNo, scNo, porCtrNm, porPlcNm, dlyCtrNm, dlyPlcNm, freExpDate, dlyCtrCd, idx) {
      e.preventDefault()
      this.negoAfterText = ''
      this.raNo = raNo
      this.scNo = scNo
      this.porCtrNm = porCtrNm
      this.porPlcNm = porPlcNm
      this.dlyCtrNm = dlyCtrNm
      this.dlyPlcNm = dlyPlcNm
      this.freExpDate = freExpDate
      this.dlyCtrCd = dlyCtrCd
      this.apv20Ft = this.apv20FtList[idx]
      this.apv40Ft = this.apv40FtList[idx]
    }
  }
}
</script>
