var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [_vm._v(" 나의 할인쿠폰 ")]),
      _c(
        "form",
        { attrs: { id: "frm2" } },
        [
          _c(
            "win-layer-pop",
            { staticClass: "my_surcharge_pop" },
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.surChargeParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "content_box beforetab" }, [
            _c("table", { staticClass: "tbl_search" }, [
              _c("colgroup", [
                _c("col", {
                  attrs: {
                    width: [_vm.serviceCtrCd === "KR" ? "60px" : "80px"],
                  },
                }),
                _c("col", { attrs: { width: "150px" } }),
                _c("col", { attrs: { width: "324px" } }),
                _c("col", { attrs: { width: "50px" } }),
                _c("col", { attrs: { width: "150px" } }),
                _c("col", { attrs: { width: "180px" } }),
                _c("col", { attrs: { width: "50px" } }),
                _c("col", { attrs: { width: "120px" } }),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.001")))]),
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "polPol",
                          "ctr-cd": _vm.params.porCtrCd,
                          "plc-cd": _vm.params.porPlcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePol },
                      }),
                    ],
                    1
                  ),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.002")))]),
                  _c(
                    "td",
                    { attrs: { colspan: "4" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "podPod",
                          "ctr-cd": _vm.params.dlyCtrCd,
                          "plc-cd": _vm.params.dlyPlcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePod },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_right" }),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.003")))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.kind,
                            expression: "params.kind",
                          },
                        ],
                        staticClass: "wid150",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "kind",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "R" } }, [
                          _vm._v("ETD 기준"),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("e-date-range-picker", {
                        attrs: {
                          id: "date_picker",
                          sdate: _vm.params.fromDt,
                          edate: _vm.params.toDt,
                          "is-debug": true,
                        },
                        on: { change: _vm.changeDateRange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "th",
                    {
                      staticStyle: {
                        "text-align": "right",
                        "padding-right": "15px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.COUP100.014")))]
                  ),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.type,
                            expression: "params.type",
                          },
                        ],
                        staticClass: "wid150",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("선택"),
                        ]),
                        _c("option", { attrs: { value: "GP" } }, [
                          _vm._v("GP"),
                        ]),
                        _c("option", { attrs: { value: "HC" } }, [
                          _vm._v("HC"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("td", { staticClass: "text_right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button blue sh",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.searchMyOceanFreight(true)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.013")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box mt10" }, [
      _c("div", {
        staticClass: "mt5",
        staticStyle: { width: "100%", height: "650px" },
        attrs: { id: "realgrid" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }